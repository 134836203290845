if (module.hot) {
  module.hot.accept();
}

// import { initCursor } from "./cursor";
import { initNav } from "./nav";
// import { initCarousel } from "./carousel";
import Grid from "./grid";
import { preloadImages } from "./utils";
import { gsap } from "gsap";
import { CSSRulePlugin } from "gsap/CSSRulePlugin";
import { DrawSVGPlugin } from "gsap/DrawSVGPlugin";

gsap.registerPlugin(CSSRulePlugin, DrawSVGPlugin);

function init() {
  // initCursor();
  initNav();

  // Preload  images
  preloadImages(".grid__item-img, .bigimg").then(() => {
    // Remove loader (loading class)
    document.body.classList.remove("loading");

    // Initialize grid
    const grid = new Grid(document.querySelector(".grid"));
  });
}

window.addEventListener("load", function () {
  init();
});

const nav_rule = CSSRulePlugin.getRule(".navbar--home .nav-open li:after");

gsap.from(nav_rule, {
  width: "0%",
  duration: 0.5,
  ease: "power1.out",
  delay: 1,
});

const mm = gsap.matchMedia();
mm.add("(max-width: 767px)", () => {
  // Draw Theme Lead In
  const theme_lead_in_animation_tl = gsap.timeline();
  theme_lead_in_animation_tl.from(".home-title__image path", {
    duration: 4,
    drawSVG: "0%",
    ease: "slow(0.7, 0.7, false)",
  });
  theme_lead_in_animation_tl.to(
    ".theme-lead-in__wrapper",
    {
      opacity: 1,
    },
    0
  );

  theme_lead_in_animation_tl.to(".home-title__image .cls-1", {
    fill: "#191919",
    duration: 2,
  });

  theme_lead_in_animation_tl.to(
    ".home-mobile-title__link",
    {
      opacity: 1,
      duration: 1,
    },
    "<+.5"
  );
});

// theme_lead_in_animation_tl.to(
//   ".theme-lead-in__arrow-right",
//   {
//     width: "100%",
//     duration: 0.5,
//   },
//   "<.5"
// );
